.container {
  position: relative;
  background: white;
  height: 50vh;
}

.wave {
  position: absolute;
  height: 75px;
  width: 100%;
  background: white;
  bottom: 0;
}

.wave::before,
.wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
}

.wave::before {
  width: 55%;
  height: 109%;
  background-color: #fff;
  right: -1.5%;
  top: 60%;
}
.wave::after {
  width: 55%;
  height: 100%;
  background-color: black;
  left: -1.5%;
  top: 40%;
}
